<template>
	<div v-loading="loading">
		<div class="top">
			<div class="company-info">
				<div class="left flex flex-align-center">
					<img class="icon" src="https://cdn.dkycn.cn/images/melyshop/2024091213520001.png" />
					<div class="title">公司名称</div>
					<span class="name ellipsis">{{ details.CompanyName || '--' }}</span>
					<div class="tag" v-if="details.IsRepeat">重复</div>
				</div>
				<div class="right" v-if="!details.IsCancel">
					<buttonPermissions :datas="permissionKey + 'Edit'" >
						<el-button size="mini" style="margin-left: 10px;" @click="editClue">编辑</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="permissionKey + 'ChangeFollowUpState'" >
						<el-button size="mini" style="margin-left: 10px;" v-if="details.FollowUpState === 1" @click="handleChangeFollowUpState">跟进中</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="permissionKey + 'Transfer'" >
						<el-button size="mini" style="margin-left: 10px;" v-if="details.FollowUpState !== 3" @click="handleTransfer">转换</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="permissionKey + 'ChangeLeader'" >
						<el-button size="mini" style="margin-left: 10px;" v-if="details.AllocateState === 2 && details.FollowUpState !== 3" @click="handleChangeLeader">更换负责人</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="permissionKey + 'Dispense'" >
						<el-button size="mini" style="margin-left: 10px;" v-if="details.AllocateState === 1" @click="handleDispense">分配</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="permissionKey + 'Repeal'" >
						<el-button size="mini" style="margin-left: 10px;" v-if="details.FollowUpState !== 3" @click="handleRepeal">作废</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="permissionKey + 'ReturnData'" >
						<el-button size="mini" style="margin-left: 10px;" v-if="details.AllocateState !== 1 && details.FollowUpState !== 3" @click="handleReturnData">退回线索池</el-button>
					</buttonPermissions>
				</div>
			</div>

			<el-row :gutter="20">
				<el-col :span="8">
					<div class="clue-item">
						<div class="clue-item-label">线索编码</div>
						<div class="clue-item-value">{{ details.ClueNo || '--' }}</div>
					</div>
					<div class="clue-item">
						<div class="clue-item-label">负责人</div>
						<div class="clue-item-value" v-if="details.EmployeeName">{{ details.EmployeeName }}({{ details.EmployeePhone }})</div>
						<div class="clue-item-value" v-else>--</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="clue-item">
						<div class="clue-item-label">分配状态</div>
						<div class="clue-item-value">{{ details.AllocateStateValue || '--' }}</div>
					</div>
					<div class="clue-item">
						<div class="clue-item-label">添加时间</div>
						<div class="clue-item-value">{{ details.AddTime || '--' }}</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="clue-item">
						<div class="clue-item-label">跟进状态</div>
						<div class="clue-item-value">{{ details.FollowUpStateValue || '--' }}</div>
					</div>
				</el-col>
			</el-row>
		</div>
		
		<div class="bottom">
			<el-tabs v-model="activeName">
			    <el-tab-pane label="详情" name="first">
					<div class="details-title">基本信息</div>
					<div class="details-content">
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">公司名称</div>
									<div class="details-item-value">{{ details.CompanyName || '--' }}</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">线索编码</div>
									<div class="details-item-value">{{ details.ClueNo || '--' }}</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">线索来源</div>
									<div class="details-item-value">
										<div class="source-title">{{ details.CRMClueFromName || '--' }}</div>
										<div class="source-content">{{ details.CRMClueFromRemark }}</div>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">分配状态</div>
									<div class="details-item-value">{{ details.AllocateStateValue || '--' }}</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">跟进状态</div>
									<div class="details-item-value">{{ details.FollowUpStateValue || '--' }}</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">负责人</div>
									<div class="details-item-value" v-if="details.EmployeeName">{{ details.EmployeeName }}({{ details.EmployeePhone }})</div>
									<div class="clue-item-value" v-else>--</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">已转换客户</div>
									<div class="details-item-value">
										<div v-if="details.CustomerCompanyName" style="color: #409EFF;cursor: pointer;" @click="handleToCompanyDetails">
											{{ details.CustomerCompanyName }}
										</div>
										<span v-else>--</span>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">联系人姓名</div>
									<div class="details-item-value">{{ details.ContactName || '--' }}</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">联系人手机号</div>
									<div class="details-item-value">{{ details.ContactPhone || '--' }}</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">联系人职务</div>
									<div class="details-item-value">{{ details.ContactPosition || '--' }}</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">固话</div>
									<div class="details-item-value">
										<span v-if="details.LandlinePhone">
											<span v-if="details.LandlinePhoneAreaCode">{{ details.LandlinePhoneAreaCode}}-</span>{{ details.LandlinePhone}}
										</span>
										<span v-else>--</span>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">销售备注</div>
									<div class="details-item-value">{{ details.SellRemark || '--' }}</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">所在地区</div>
									<div class="details-item-value">
										<span v-if="details.Province">{{ details.Province }}-{{ details.City }}-{{ details.Area }}</span>
										<span v-else>--</span>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="details-item">
									<div class="details-item-label">详细地址</div>
									<div class="details-item-value">{{ details.Address || '--' }}</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div v-if="details.IsCancel">
						<div class="details-title">其他信息</div>
						<div class="details-content">
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="details-item">
										<div class="details-item-label">作废原因</div>
										<div class="details-item-value">
											<div class="source-title">{{ details.CancelTypeValue || '--' }}</div>
											<div class="source-content">{{ details.CancelTypeRemark}}</div>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="details-item">
										<div class="details-item-label">作废备注</div>
										<div class="details-item-value">{{ details.CancelRemark || '--' }}</div>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="details-item">
										<div class="details-item-label">作废时间</div>
										<div class="details-item-value">{{ details.CancelTime || '--' }}</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="details-item">
										<div class="details-item-label">操作人</div>
										<div class="details-item-value" v-if="details.CancelEmployeeName">{{ details.CancelEmployeeName }}({{ details.CancelEmployeePhone }})</div>
										<div class="clue-item-value" v-else>--</div>
									</div>
								</el-col>
							</el-row>
							
						</div>
					</div>
					
					
				</el-tab-pane>
			    <el-tab-pane label="日志" name="second">
					<div style="font-size: 14px;color: #606266;margin-bottom: 10px;">线索日志</div>
					<div style="max-width: 900px;">
						<el-timeline style="padding-left: 0">
						    <el-timeline-item v-for="item in records" :key="item.Id" :timestamp="item.AddTime" placement="top">
						      <el-card>
						        <div style="font-size: 14px;color: #606266">{{ item.RecordTypeValue }}</div>
						        <div style="font-size: 14px;color: #999;margin-top: 16px;">{{ item.Remark }}</div>
						      </el-card>
						    </el-timeline-item>
						  </el-timeline>
					</div>
					
				</el-tab-pane>
			</el-tabs>
		</div>

		<!-- 作废线索 -->
		<el-dialog class="clue-dialog" title="作废线索" :visible.sync="repeal.visible" width="450px">
			<el-form :model="repeal.form" :rules="repeal.rules" label-width='88px' ref="repealForm">
				<el-form-item label="作废原因:" prop="CancelType">
					<el-select v-model="repeal.form.CancelType" placeholder="请选择" style="width:311px;">
						<el-option label="客户暂时无需求" :value="1"></el-option>
						<el-option label="联系不上客户" :value="2"></el-option>
						<el-option label="其他" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" prop="CancelTypeRemark" v-if="repeal.form.CancelType===3">
					<el-input placeholder="" v-model="repeal.form.CancelTypeRemark" style="width: 311px;" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="备注:" prop="CancelRemark">
					<el-input type="textarea" :rows="3" v-model="repeal.form.CancelRemark" resize="none" style="width: 311px;" maxlength="500"></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="repeal.visible=false">关闭</el-button>
				<el-button type="primary" :loading="repeal.loading" @click="handleRepealSave">确定</el-button>
			</div>
		</el-dialog>
		
		<!-- 退回线索池 -->
		<el-dialog class="clue-dialog" title="退回线索池" :visible.sync="returnData.visible" width="450px">
			<el-form :model="returnData.form" :rules="returnData.rules" label-width='88px' ref="returnDataForm">
				<el-form-item label="退回原因:" prop="ReturnType">
					<el-select v-model="returnData.form.ReturnType" placeholder="请选择" style="width:311px;">
						<el-option label="客户暂时无需求" :value="1"></el-option>
						<el-option label="联系不上客户" :value="2"></el-option>
						<el-option label="其他" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" prop="ReturnTypeRemark" v-if="returnData.form.ReturnType===3">
					<el-input placeholder="" v-model="returnData.form.ReturnTypeRemark" style="width: 311px;" maxlength="100"></el-input>
				</el-form-item>
				<el-form-item label="备注:" prop="ReturnRemark">
					<el-input type="textarea" :rows="3" v-model="returnData.form.ReturnRemark" resize="none" style="width: 311px;" maxlength="500"></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="returnData.visible=false">关闭</el-button>
				<el-button type="primary" :loading="returnData.loading" @click="handleReturnDataSave">确定</el-button>
			</div>
		</el-dialog>

		<!-- 更换销售负责人 -->
		<el-dialog class="clue-dialog" title="更换销售负责人" :visible.sync="changeLeader.visible" width="450px">
			<el-form :model="changeLeader.form" :rules="changeLeader.rules" :inline="true" ref="changeLeaderForm">
				<el-form-item label="将负责人更换为：" filterable prop="MallEmployeeId">
					<el-select v-model="changeLeader.form.MallEmployeeId" placeholder="请选择" style="width:398px;">
						<el-option v-for="item in employeeList" :key="item.Id" :label="item.label" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="changeLeader.visible=false">关闭</el-button>
				<el-button type="primary" :loading="changeLeader.loading" @click="handleChangeLeaderSave">确定</el-button>
			</div>
		</el-dialog>

		<!-- 分配线索 -->
		<el-dialog class="clue-dialog" title="分配线索" :visible.sync="dispense.visible" width="450px">
			<el-form :model="dispense.form" :rules="dispense.rules" :inline="true" ref="dispenseForm">
				<el-form-item label="将线索分配给：" prop="MallEmployeeId">
					<el-select v-model="dispense.form.MallEmployeeId" filterable placeholder="请选择" style="width:398px;">
						<el-option v-for="item in employeeList" :key="item.Id" :label="item.label" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div style="padding-top:20px;text-align: right;">
				<el-button @click="dispense.visible=false">关闭</el-button>
				<el-button type="primary" :loading="dispense.loading" @click="handleDispenseSave">确定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		cRMClueCancel,
		cRMClueAllocate,
		cRMClueAllocateJudgeState,
		cRMClueChangeMallEmployeeId,
		cRMClueFromFilterBoxList,
		cRMClueInfo,
		cRMClueFollowUp,
		cRMClueReturnPool,
		cRMClueRecordList
	} from '@/api/cluesManage';

	import {
		employeelistbystate
	} from '@/api/sv1.0.0.js';

	import config from '@/config/index';
	
	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {buttonPermissions},
		data() {
			return {
				employeeList: [],
				details: {},
				activeName: 'first',
				records: [],
				loading: false,
				permissionKey: '',
				
				//作废
				repeal: {
					visible: false,
					loading: false,
					form: {
						CancelType: '',
						CancelTypeRemark: '',
						CancelRemark: ''
					},
					rules: {
						CancelType: {
							required: true,
							message: '请选择作废原因',
							trigger: 'change'
						}
					}
				},
				
				//退回线索池
				returnData: {
					visible: false,
					loading: false,
					form: {
						ReturnType: '',
						ReturnTypeRemark: '',
						ReturnRemark: ''
					},
					rules: {
						ReturnType: {
							required: true,
							message: '请选择退回原因',
							trigger: 'change'
						}
					}
				},

				//更换销售负责人
				changeLeader: {
					visible: false,
					loading: false,
					form: {
						MallEmployeeId: ''
					},
					rules: {
						MallEmployeeId: {
							required: true,
							message: '请选择员工',
							trigger: 'change'
						}
					}
				},

				//分配线索
				dispense: {
					visible: false,
					loading: false,
					form: {
						MallEmployeeId: ''
					},
					rules: {
						MallEmployeeId: {
							required: true,
							message: '请选择员工',
							trigger: 'change'
						}
					}
				},

			}
		},
		methods: {
			async getDetails() {
				this.loading = true;

				try {
					let params = {
						Id: this.id
					};
					const res = await cRMClueInfo(params);
					this.details = res.Result;
				} catch (e) {
				}
				this.loading = false;
			},
			
			async getRecords() {
				try {
					let params = {
						CRMClueId: this.id,
						Skip: 0,
						Take: 9999
					};
					const res = await cRMClueRecordList(params);
					this.records = res.Result.Results;
				} catch (e) {
				}
			},
			
			/**
			 * 跳转客户详情
			 */
			handleToCompanyDetails(){
				if (!this.details.CustomerId) return;
				this.$router.push({
					name: 'SellCustomerDetail',
					query: { Id: this.details.CustomerId }
				})
			},
			
			/**
			 * 编辑线索
			 */
			editClue() {
				this.$router.push({
					name: 'clueDataEdit',
					query: {
						id: this.id
					}
				})
			},

			/**
			 *  获取在职员工列表
			 */
			async getEmployeeList() {
				try {
					const res = await employeelistbystate({ EmployeeState: -1 });
					this.employeeList = res.Result.map(item => {
						item.label = item.EmployeeName + '(' + item.Phone + ')';
						return item;
					});
				} catch (e) {
					console.log(e)
				}
			},
			
			/**
			 * 更改跟进状态
			 */
			handleChangeFollowUpState(){
				this.$confirm('该操作不可逆，是否确认将线索状态改为【跟进中】？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureChangeFollowUpState();
				})
			},
			async sureChangeFollowUpState(){
				try{
					let params = {
						Id: this.id
					};
					const res = await cRMClueFollowUp(params);
					this.$message.success('操作成功');
					this.getDetails();
				}catch(e){
				}
			},

			/**
			 *  作废
			 */
			handleRepeal(e) {
				this.selectData = e;
				this.repeal.visible = true;
				this.$nextTick(() => {
					this.$refs['repealForm'].resetFields()
				})
			},
			handleRepealSave() {
				this.repeal.loading = true;
				this.$refs['repealForm'].validate((valid) => {
					if (valid) {
						this.sureRepeal();
					} else {
						this.repeal.loading = false;
					}
				});
			},
			async sureRepeal() {
				try {
					await cRMClueCancel({ Id: this.id, ...this.repeal.form });
					this.repeal.visible = false;
					this.$message.success('操作成功');
					this.getDetails();
				} catch (e) {
					//TODO handle the exception
				}

				this.repeal.loading = false;
			},


			/**
			 * 更换负责人
			 */
			handleChangeLeader(e) {
				this.selectData = e;
				this.changeLeader.visible = true;
				this.$nextTick(() => {
					this.$refs['changeLeaderForm'].resetFields()
				})
			},
			handleChangeLeaderSave() {
				this.changeLeader.loading = true;
				this.$refs['changeLeaderForm'].validate((valid) => {
					if (valid) {
						this.sureChangeLeader();
					} else {
						this.changeLeader.loading = false;
					}
				});
			},
			async sureChangeLeader() {
				try {
					await cRMClueChangeMallEmployeeId({ Id: this.id, ...this.changeLeader.form });
					this.changeLeader.visible = false;
					this.$message.success('操作成功');
					this.getDetails();
				} catch (e) {
					console.log(e)
					//TODO handle the exception
				}

				this.changeLeader.loading = false;
			},


			/**
			 * 分配线索
			 */
			handleDispense(e) {
				this.selectData = e;
				this.dispense.visible = true;
				this.$nextTick(() => {
					this.$refs['dispenseForm'].resetFields()
				})
			},
			handleDispenseSave() {
				this.dispense.loading = true;
				this.$refs['dispenseForm'].validate((valid) => {
					if (valid) {
						this.sureDispense();
					} else {
						this.dispense.loading = false;
					}
				});
			},
			async sureDispense() {
				try {
					await cRMClueAllocateJudgeState({ Ids: [this.id] });
					await cRMClueAllocate({ Id: this.id, ...this.dispense.form });
					this.dispense.visible = false;
					this.$message.success('操作成功');
					this.getDetails();
				} catch (e) {
					//TODO handle the exception
				}

				this.dispense.loading = false;
			},
			
			
			/**
			 *退回线索池
			 */
			handleReturnData(e) {
				this.selectData = e;
				this.returnData.visible = true;
				this.$nextTick(() => {
					this.$refs['returnDataForm'].resetFields()
				})
			},
			handleReturnDataSave() {
				this.returnData.loading = true;
				this.$refs['returnDataForm'].validate((valid) => {
					if (valid) {
						this.sureReturnData();
					} else {
						this.returnData.loading = false;
					}
				});
			},
			async sureReturnData() {
				try {
					await cRMClueReturnPool({ Id: this.id, ...this.returnData.form });
					this.returnData.visible = false;
					this.$message.success('操作成功');
					this.getDetails();
				} catch (e) {
					//TODO handle the exception
				}
			
				this.returnData.loading = false;
			},
			
			handleTransfer(){
				this.$router.push({
					name: 'SellCustomerEdit',
					query: {
						info: JSON.stringify({
							...this.details,
							CRMClueId:this.$route.query.id,
							ClueCompanyName:this.details.CompanyName,
						}) 
					}
				})
			}
			
		},
		created() {
			this.id = this.$route.query.id;
			this.permissionKey = this.$route.query.key;
			this.getDetails();
			this.getRecords();
			this.getEmployeeList();
		}
	}
</script>

<style lang="less" scoped>
	
	.bottom{
		background: #fff;
		padding: 15px;
		margin-top: 10px;
		
		.details-title{
			height: 30px;
			padding-left: 10px;
			background: #F8F8F9;
			border: 1px solid #EBEEF5;
			font-size: 14px;
			color: #606266;
			display: flex;
			align-items: center;
		}
		
		.details-item{
			display: flex;
			font-size: 14px;
			line-height: 22px;
			margin-top: 20px;
			
			// &:first-child{
			// 	margin-bottom: 8px;
			// }
			
			.details-item-label{
				width: 120px;
				margin-left: 10px;
				color: #999;
				white-space: nowrap;
			}
			.details-item-value{
				color: #606266;
				flex: 1;
				overflow: hidden;
				
				display: flex;
				
				.source-title{
					margin-right: 10px;
				}
				.source-content{
					flex: 1;
				}
			}
		}
		
		.details-content{
			margin-right: 100px;
			padding-bottom: 40px;
		}
	}
	
	.top {
		background: #fff;
		padding: 15px;
		
		.clue-item{
			display: flex;
			font-size: 14px;
			line-height: 22px;
			align-items: center;
			
			
			&:first-child{
				margin-bottom: 8px;
			}
			
			.clue-item-label{
				width: 120px;
				color: #999;
				white-space: nowrap;
			}
			.clue-item-value{
				color: #606266;
				white-space: nowrap;
			}
		}
		
		.company-info {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		
			.left {
				flex: 1;
				overflow: hidden;
			}
		
			.right {
				white-space: nowrap;
			}
		
			.icon {
				display: block;
				width: 50px;
			}
		
			.title {
				font-size: 18px;
				color: #999;
				margin-left: 10px;
			}
		
			.name {
				font-size: 18px;
				color: #606266;
				margin-left: 30px;
				max-width: 50%;
			}
		
			.tag {
				width: 32px;
				height: 18px;
				background: #F56C6C;
				color: #fff;
				font-size: 12px;
				margin-left: 10px;
				line-height: 1;
				white-space: nowrap;
		
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.clue-dialog ::v-deep .el-dialog__body{
		padding: 10px 20px 20px;
	}
</style>